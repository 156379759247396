<template>
    <div class="sports_bet_list">
        <table class="table_sports_bet" style="margin-top: 10px;border: 2px solid #ffa500"
               v-for="item in betList">
            <tr style="border-top: 4px solid #0d88c1"
                :class="{'bg-danger':item.leisureGame.autoclosed}">
                <th style="width: 10%;">일회차</th>
                <th style="width: 25%;">경기유형</th>
                <th style="width: 10%;">경기일자</th>
                <th style="width: 10%;">베팅시간</th>
                <th style="width: 17%;">IP</th>
                <th style="width: 10%;">번호</th>
                <th style="width: 8%;">게임번호</th>
            </tr>
            <tr>
                <td class="bg-blue">{{item.leisureGame.sequence}}</td>
                <td>{{item.kindConfig.kindName}}</td>
                <td>{{item.leisureGame.startDayStr}}</td>
                <td>{{item.createTime|datef('MM-DD HH:mm:ss')}}</td>
                <td>{{item.betIp}}</td>
                <td>{{item.id}}</td>
                <td>{{item.gameId}}</td>

            </tr>
            <tr>
                <th>회원</th>
                <th>선택값Text</th>
                <th>베팅결과</th>
                <th>정산여부</th>
                <th>배당</th>
                <th>베팅금액</th>
                <th>예상당첨</th>
            </tr>
            <tr>
                <td @click="editUser(item.userId,managerConst.MODE_EDIT)" style="cursor: pointer">
                    <span class="badge badge-success">{{item.user.nickname}}</span>
                </td>
                <td class="bg-gradient-success">
                    <span class="text-blue" style="font-weight: bold">({{item.selectedValueText}})</span>
                    <span> {{item.attributeConfig.attrName}}</span>
                    <span v-if="item.leisureGame.homeTeamName != null">[{{item.leisureGame.homeTeamName}} vs {{item.leisureGame.awayTeamName}}]</span>
                    <span v-if="item.leisureGame.homeTeamScore >=0 && item.leisureGame.awayTeamScore>=0">[{{item.leisureGame.homeTeamScore}}:{{item.leisureGame.awayTeamScore}}]</span>

                </td>
                <td>
                    <span v-if="item.betResult == managerConst.SportsBet.BET_RESULT_WAITING">대기중</span>
                    <span v-if="item.betResult == managerConst.SportsBet.BET_RESULT_WIN"
                          class="badge badge-primary">당첨</span>
                    <span v-if="item.betResult == managerConst.SportsBet.BET_RESULT_LOSE"
                          class="badge badge-danger">낙첨</span>
                    <span v-if="item.betResult == managerConst.SportsBet.BET_RESULT_CANCEL" class="badge badge-danger">취소</span>
                    <span v-if="item.betResult == managerConst.SportsBet.BET_RESULT_SPECIALCASE"
                          class="badge badge-danger">적특</span>
                    <span>
                         <br>{{item.operator}}
                    </span>

                </td>
                <td>
                    <span v-if="item.status == managerConst.SportsBet.STATUS_COMPLITE">정산완료</span>
                    <span v-if="item.status == managerConst.SportsBet.STATUS_WAITING">정산대기중</span>
                </td>
                <td>{{item.betOdds}}</td>
                <td>{{item.betCash|comma}}원</td>
                <td>{{item.totalCash|comma}}원</td>
            </tr>
            <tr>
                <td colspan="8" style="text-align: right;line-height: 40px;background-color: #cfcfd1!important;">

                    <el-button type="warning" size="mini"
                            v-if="item.ishighamount == managerConst.YES"
                            @click="updateHighAmountBet(item.id, managerConst.NOT)">
                        확인(고액)
                    </el-button>
                    <el-button  type="warning" size="mini"
                            v-if="item.alarm == managerConst.UNCHECKED"
                            @click="updateAlarm(item.id, managerConst.CHECKED)">
                        확인(특)
                    </el-button>
                    <el-button  type="danger" size="mini"
                            v-if="item.display == managerConst.YES"
                            @click="updateDisplay(item.id, managerConst.NOT)">
                        삭제
                    </el-button>
                    <el-button  type="danger" size="mini"
                            v-if="item.display == managerConst.NOT"
                            @click="updateDisplay(item.id, managerConst.YES)">
                        삭제복구
                    </el-button>
                    <el-button  type="danger" size="mini"
                            v-if="item.betResult == managerConst.SportsBet.BET_RESULT_WAITING"
                            @click="cancelBet(item.id)">취소
                    </el-button>
                </td>
            </tr>

        </table>
    </div>
</template>

<script>
    import managerConst from "../../common/administrator/managerConst";
    import Vue from "vue";
    import UserEditToast from "./UserPanel/UserEditToast";
    import {updateLeisureGame, updateLeisureGameResultCancel} from "../../network/manager/leisureBetRequest";
    import {initSportsBetInfo} from "../../network/manager/sportsBetRequest";

    Vue.prototype.$userEditToast = UserEditToast
    export default {
        name: "LeisureGameBetComp",
        data() {
            return {
                managerConst: managerConst,
                betList: [],
            }
        },
        props: {
            list: {
                type: Array,
                default() {
                    return [];
                }
            },

        },
        methods: {
            editUser(userId, mode) {
                this.$userEditToast({'userId': userId, 'mode': mode})
            },
            updateDisplay(id, s) {
                let update ={}
                update.id=id
                update.display=s
                updateLeisureGame(update).then(res=>{
                    this.$emit('leisureRefresh')
                })
            },
            updateAlarm(id, s) {
                let update ={}
                update.id=id
                update.alarm=s
                updateLeisureGame(update).then(res=>{
                    this.$emit('leisureRefresh')
                })
            },
            updateHighAmountBet(id, s) {
                let update ={}
                update.id=id
                update.ishighamount=s
                updateLeisureGame(update).then(res=>{
                    this.$emit('leisureRefresh')
                })
            },
            cancelBet(id) {
                this.$confirm('해당 베팅내역을  취소 하시겠습니까?', '베팅내역 취소', {
                    confirmButtonText: '예',
                    cancelButtonText: '아니오',
                    type: 'warning'
                }).then(() => {
                    let update = {}
                    update.id = id;
                    updateLeisureGameResultCancel(update).then(res => {
                        if (res.data.success) {
                            this.$emit('leisureRefresh')
                        } else {
                            this.$message({
                                showClose: true,
                                duration: 3000,
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        duration: 1000,
                        message: '초기화 취소되였습니다'
                    });
                });

            },
            initBetInfo(id) {

            },
        },
        watch: {
            list: {
                handler(newVal) {
                    this.betList = newVal
                },
                immediate: true,
                deep: true
            },
        }
    }
</script>

<style scoped>
    .sports_bet_list {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .table_sports_bet {
        width: 100%;
        margin-bottom: 10px;
    }

    .table_bet_games {
        width: 100%;
    }

    .table_bet_games td {
        line-height: 40px !important;
        border-bottom: 1px solid #cfcfd1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 2px;
    }
</style>