import httpManager from "../axios/httpManager";

export function getHonorCasinoBetlist(search, pageNum, pageSize) {
    let url = '/casino/nxzone/bet_list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize + '&t=' + new Date().getTime();
    return httpManager.post(url, search);
}

export function gettHonorCasinoAccountBalance(userId) {
    return httpManager.get('/casino/nxzone/get_account_balance?userId=' + userId + '&t=' + new Date().getTime())
}

export function subjecttHonorCasinoMemberCasinoCash(userId) {
    return httpManager.get('/casino/nxzone/subject_member_casino_cash?userId=' + userId + '&t=' + new Date().getTime())
}
export function sysData(time) {
    return httpManager.get('/casino/nxzone/syns_data?time=' + time + '&t=' + new Date().getTime())
}
